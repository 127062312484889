import axios from "axios";
import React, { Component } from "react";
export const API_TYPE = {
  FETCH_LIST: "/fetchlist",
  FETCH: "/fetch",

  CREATE: "/create",
  UPDATE: "/update",
  DELETE: "/delete",

  RESET: "/reset",
};
export const API_SSR_BASE_URL = process.env.NEXT_PUBLIC_HOST_SERVER_URL + "/"; //뒤에 resourceName이 붙는다.

export const API_BASE_URL = process.env.NEXT_PUBLIC_REACT_APP_API_URL + "/"; //뒤에 resourceName이 붙는다.

export const getFileParamSettings = (params: any) => {
  return {
    params: params,

    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
  };
};

export const getParamSettings = (params: any) => {
  return {
    params: params,

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
  };
};

export const getParamSettingsSSR = (params: any, cookies: any) => {
  return {
    params: params,

    headers: {
      Origin: process.env.NEXT_PUBLIC_URL,

      Cookie: cookies,

      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
  };
};

export const getSSR = (resourceName: string, params: {}, cookies: any) => {
  const url = `${API_SSR_BASE_URL}${resourceName}`;

  return axios.get(url, getParamSettingsSSR(params, cookies));
};

export const get = (resourceName: string, params: {}) => {
  const url = `${API_BASE_URL}${resourceName}`;

  return axios.get(url, getParamSettings(params));
};

export const postSSR = (
  resourceName: string,
  formData: any,
  params: {},
  cookies: any
) => {
  let url = "";

  if (resourceName.includes("/")) {
    //delete 의 경우 url에 id가 붙기 때문에.
    //나중에 delete/create 등이 가장 먼저 url에 붙도록 수정하자.
    url = `${API_BASE_URL}${resourceName}`;
  } else {
    url = `${API_BASE_URL}${resourceName}${API_TYPE.CREATE}`;
  }

  return axios.post(url, formData, getParamSettingsSSR(params, cookies));
};
export const post = (resourceName: string, formData: any, params: {}) => {
  let url = "";

  if (resourceName.includes("/")) {
    //delete 의 경우 url에 id가 붙기 때문에.
    //나중에 delete/create 등이 가장 먼저 url에 붙도록 수정하자.
    url = `${API_BASE_URL}${resourceName}`;
  } else {
    url = `${API_BASE_URL}${resourceName}${API_TYPE.CREATE}`;
  }

  return axios.post(url, formData, getParamSettings(params));
};

export const filePost = (url: string, formData: any, params: {}) => {
  return axios.post(
    `${API_BASE_URL}${url}`,
    formData,
    getFileParamSettings(params)
  );
};

export const put = (resourceName: string, formData: any, params: {}) => {
  const url = `${API_BASE_URL}${resourceName}${API_TYPE.UPDATE}}`;

  return axios.put(url, formData, getParamSettings(params));
};

export const postWithSub = (
  resourceName: string,
  subResouceName: string,
  formData: any,
  params: {}
) => {
  const url =
    API_BASE_URL + resourceName + API_TYPE.UPDATE + "/" + subResouceName;

  return axios.post(url, formData, getParamSettings(params));
};
